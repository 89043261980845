import { MenuItem, Select, Stack, styled } from '@mui/material';

export const ScheduleContainer = styled('div')(() => ({
  margin: '8px 0',
}));

export const Schedule = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: '16px 24px',
  display: 'inline-flex',
  borderRadius: '4px',

  '&:hover': {
    borderColor: 'var(--mui-palette-primary-main)',
  },
}));

export const ScheduleSelect = styled(Select)(({ theme }) => ({
  '&::before, &::after': {
    border: 'none',
  },

  height: '32px',
  borderRadius: '4px',
  '&:hover': {
    '&::before, &::after': {
      border: 'none !important',
    },

    backgroundColor: 'var(--mui-palette-primary-dark)',
  },

  '& .MuiSelect-select': {
    display: 'flex !important',
    alignItems: 'center',
    padding: '0px 12px',
    height: '32px',

    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiSelect-select .MuiTypography-root': {
    marginRight: '12px',
  },
  '& i': {
    marginLeft: '8px',
    right: 0,
    border: '1px solid green',
  },
  '& .MuiList-root .MuiMenuItem-root p': {
    fontWeight: '400',
  },
  '& .MuiTypography-root': {
    fontWeight: '600',
    color: theme.palette.mode === 'dark' ? 'white' : '#272742',
  },

  '& .MuiMenuItem-root': {
    borderRadius: 0,
  },
}));

export const ScheduleMenuItem = styled(MenuItem)(() => ({
  borderRadius: 0,

  '&:hover': {
    backgroundColor: 'var(--mui-palette-primary-dark)',
  },
}));
