import { Button } from '@mui/material';
import {
  BackupStatus,
  ResourceType,
  type InventoryResource,
} from '@repo/api-gw-sdk';
import { useRouter } from 'next/navigation';
import React, { forwardRef, useState } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import type { OptionType } from '@/@core/components/option-menu/types';
import { AppsEditor } from '@/components/panels/instance/tabs/classificationPanels/appsEditor';
import { DataClassEditor } from '@/components/panels/instance/tabs/classificationPanels/dataClassEditor';
import { EnvironmentEditor } from '@/components/panels/instance/tabs/classificationPanels/environmentEditor';
import { RestartApplicationDetection } from '@/components/panels/instance/tabs/classificationPanels/restartApplicationDetection';
import { RestartAutomaticDataClassClassification } from '@/components/panels/instance/tabs/classificationPanels/restartAutomaticDataClassification';
import { RestartAutomaticEnvironmentDetection } from '@/components/panels/instance/tabs/classificationPanels/restartAutomaticEnvironmentDetection';
import { Icon } from '@/components/shared/icon';
import { useEnvironment } from '@/contexts/useEnvironment';
import { useRoles } from '@/contexts/useRoles';

import CancelBackupExclusionDialog from './cancelBackupExclusionDialog';
import ExcludeFromBackupDialog from './excludeFromBackupDialog';
import InventoryBulkPolicyAssignmentDialog from './inventoryBulkPolicyAssignmentDialog';
import TakeSnapshotDialog from './takeSnapshotDialog';

const richIcon = (icon: string) => (
  <i className={`${icon} w-[20px] h-[20px] mr-[12px]`} />
);

const containedItemText = (text: string) => (
  <>
    <span className='mr-[12px]'>{text}</span>
    <i className='material-symbols-chevron-right-rounded w-[20px] h-[20px] ml-auto' />
  </>
);

export default function InventoryMultiInstanceMenu(props: {
  entities: InventoryResource[];
}) {
  const { entities } = props;
  const [excludeFromBackupDialogOpen, setExcludeFromBackupDialogOpen] =
    useState(false);
  const [
    cancelBackupExclusionsDialogOpen,
    setCancelBackupExclusionsDialogOpen,
  ] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [takeSnapshotDialogOpen, setTakeSnapshotDialogOpen] = useState(false);
  const [dataClassesDiagOpen, setDataClassesDiagOpen] = useState(false);
  const [envDiagOpen, setEnvDiagOpen] = useState(false);
  const [appDialogOpen, setAppDialogOpen] = useState(false);
  const [resumeAppsDialogOpen, setResumeAppsDialogOpen] = useState(false);
  const [resumeDataClassesDialogOpen, setResumeDataClassesDialogOpen] =
    useState(false);
  const [resumeEnvDialogOpen, setResumeEnvDialogOpen] = useState(false);

  const { isAuthorizedResource } = useRoles();
  const { isDemo, isDev } = useEnvironment();
  const router = useRouter();

  const actions: OptionType[] = [
    {
      testId: 'backup-management',
      text: 'Backup Management',
      groupHeader: true,
    },
    {
      testId: 'assign-to-backup-policy',
      text: 'Assign to backup policy',
      icon: richIcon('material-symbols-bookmark-add-outline'),
      menuItemProps: {
        disabled: entities.some(
          (entity) => !isAuthorizedResource('update:policies', entity)
        ),
        onClick: () => setAssignDialogOpen(true),
      },
    },
    {
      testId: 'take-snapshot',
      text: 'Take snapshot',
      icon: richIcon('material-symbols-backup-outline-rounded'),
      menuItemProps: {
        disabled:
          entities.some(
            (entity) => !isAuthorizedResource('create:jobs', entity)
          ) ||
          // temporary disable option for cross cloud resources
          entities.some(
            (resource) => resource.cloudProvider !== entities[0].cloudProvider
          ) ||
          entities.every(
            (entity) =>
              entity.backupStatus === BackupStatus.Terminated ||
              entity.backupStatus === BackupStatus.Disconnected
          ),
        onClick: () => setTakeSnapshotDialogOpen(true),
      },
    },
    {
      testId: 'exclude-from-backup',
      text: 'Exclude from backup',
      icon: richIcon('material-symbols-hide-source-outline-rounded'),
      menuItemProps: {
        disabled:
          entities.some(
            (entity) => !isAuthorizedResource('update:exclude_resource', entity)
          ) ||
          entities.every(
            (entity) => entity.backupStatus === BackupStatus.ExcludedFromBackup
          ),
        onClick: () => setExcludeFromBackupDialogOpen(true),
      },
    },
    {
      testId: 'cancel-backup-exclusions',
      text: 'Cancel backup exclusion',
      icon: richIcon('material-symbols-add-circle-outline'),
      menuItemProps: {
        disabled: entities.some(
          (entity) =>
            !isAuthorizedResource('update:exclude_resource', entity) ||
            entity.backupStatus !== BackupStatus.ExcludedFromBackup
        ),
        onClick: () => setCancelBackupExclusionsDialogOpen(true),
      },
    },
    {
      testId: 'auto-classifications',
      text: 'AUTO-CLASSIFICATIONS',
      groupHeader: true,
    },
    {
      testId: 'pause-and-override',
      text: containedItemText('Pause and override'),
      icon: richIcon('mdi-stop-circle-outline'),
      subMenu: [
        {
          testId: 'pause-and-override-data-classes',
          text: 'Data classes',
          menuItemProps: {
            disabled: entities.some(
              (entity) =>
                !isAuthorizedResource('update:data_classification', entity)
            ),
            onClick: () => setDataClassesDiagOpen(true),
          },
        },
        {
          testId: 'pause-and-override-environment',
          text: 'Environment',
          menuItemProps: {
            disabled: entities.some(
              (entity) =>
                !isAuthorizedResource(
                  'update:environment_classification',
                  entity
                )
            ),
            onClick: () => setEnvDiagOpen(true),
          },
        },
        {
          testId: 'pause-and-override-apps',
          text: 'Applications',
          menuItemProps: {
            disabled: entities.some(
              (entity) =>
                !isAuthorizedResource('update:apps_classification', entity)
            ),
            onClick: () => setAppDialogOpen(true),
          },
        },
      ],
    },
    {
      testId: 'resume',
      text: containedItemText('Resume'),
      icon: richIcon('material-symbols-motion-play-outline-rounded'),
      subMenu: [
        {
          testId: 'resume-data-classes',
          text: 'Data classes',
          menuItemProps: {
            disabled: entities.some(
              (entity) =>
                !isAuthorizedResource('update:data_classification', entity)
            ),
            onClick: () => setResumeDataClassesDialogOpen(true),
          },
        },
        {
          testId: 'resume-environment',
          text: 'Environment',
          menuItemProps: {
            disabled: entities.some(
              (entity) =>
                !isAuthorizedResource(
                  'update:environment_classification',
                  entity
                )
            ),
            onClick: () => setResumeEnvDialogOpen(true),
          },
        },
        {
          testId: 'resume-apps',
          text: 'Applications',
          menuItemProps: {
            disabled: entities.some(
              (entity) =>
                !isAuthorizedResource('update:apps_classification', entity)
            ),
            onClick: () => setResumeAppsDialogOpen(true),
          },
        },
      ],
    },
    ...(isDemo || isDev
      ? [
          {
            testId: 'exploration',
            text: 'EXPLORATION',
            groupHeader: true,
          },
          {
            testId: 'explore-databases',
            text: 'Explore databases',
            icon: richIcon('material-symbols-frame-inspect-rounded'),
            menuItemProps: {
              disabled: !entities.every(
                (resource) =>
                  resource.resourceType === ResourceType.AwsRds ||
                  resource.resourceType === ResourceType.AtlasMongodbCluster ||
                  resource.resourceType === ResourceType.AzurePostgresql ||
                  resource.resourceType === ResourceType.AzureMysql ||
                  resource.resourceType === ResourceType.AzureSqlDatabase ||
                  ((resource.resourceType === ResourceType.AwsEc2 ||
                    resource.resourceType ===
                      ResourceType.AzureVirtualMachine) &&
                    !!resource.classifications?.appsDetails?.apps?.find(
                      (app) => app.paths?.length
                    ))
              ),
              onClick: () =>
                router.push(
                  `/databases/${entities.map((entity) => entity.id).join(',')}`
                ),
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <OptionMenu
        options={actions}
        Component={forwardRef<HTMLButtonElement>((props, ref) => (
          <Button
            className='h-[32px] p-[4px] pr-[12px]'
            variant='outlined'
            ref={ref}
            {...props}
          >
            <Icon iconClass='material-symbols-more-horiz mr-[8px]' />
            Actions
          </Button>
        ))}
      />
      {assignDialogOpen && (
        <InventoryBulkPolicyAssignmentDialog
          resourceIds={entities.map((entity) => entity.id)}
          onClose={() => setAssignDialogOpen(false)}
        />
      )}
      {takeSnapshotDialogOpen && (
        <TakeSnapshotDialog
          entities={entities}
          onClose={() => setTakeSnapshotDialogOpen(false)}
        />
      )}
      {dataClassesDiagOpen && (
        <DataClassEditor
          open={true}
          onClose={() => setDataClassesDiagOpen(false)}
          entities={entities}
        />
      )}
      {envDiagOpen && (
        <EnvironmentEditor
          open={true}
          onClose={() => setEnvDiagOpen(false)}
          entities={entities}
        />
      )}
      {appDialogOpen && (
        <AppsEditor
          open={true}
          onClose={() => setAppDialogOpen(false)}
          entities={entities}
        />
      )}
      {resumeAppsDialogOpen && (
        <RestartApplicationDetection
          open={true}
          onClose={() => setResumeAppsDialogOpen(false)}
          entities={entities}
        />
      )}
      {resumeDataClassesDialogOpen && (
        <RestartAutomaticDataClassClassification
          open={true}
          onClose={() => setResumeDataClassesDialogOpen(false)}
          entities={entities}
        />
      )}
      {resumeEnvDialogOpen && (
        <RestartAutomaticEnvironmentDetection
          open={true}
          onClose={() => setResumeEnvDialogOpen(false)}
          entities={entities}
        />
      )}
      <ExcludeFromBackupDialog
        entities={entities}
        isOpen={excludeFromBackupDialogOpen}
        onClose={() => setExcludeFromBackupDialogOpen(false)}
      />
      <CancelBackupExclusionDialog
        entities={entities}
        isOpen={cancelBackupExclusionsDialogOpen}
        onClose={() => setCancelBackupExclusionsDialogOpen(false)}
      />
    </>
  );
}
