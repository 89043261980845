import { Box, Checkbox, Stack } from '@mui/material';
import type { BackupPolicy } from '@repo/api-gw-sdk';
import { useState } from 'react';

import BackupPolicyTag from '@/components/backupPolicy/BackupPolicyTag';
import MainDialog from '@/components/dialogs/mainDialog';
import { useDAL } from '@/data/dal';

interface InventoryBulkPolicyAssignmentDialogProps {
  onClose: () => void;
  resourceIds: string[];
}

export default function InventoryBulkPolicyAssignmentDialog(
  props: InventoryBulkPolicyAssignmentDialogProps
) {
  const dal = useDAL();
  const { body, isLoading } = dal.backupPolicy.list();
  const [selectedPolicies, setSelectedPolicies] = useState<BackupPolicy[]>([]);
  const [updating, setUpdating] = useState(false);

  const assignToPolicy = async () => {
    try {
      setUpdating(true);

      for (const policy of selectedPolicies) {
        for (const resourceId of props.resourceIds) {
          if (policy.exclude?.includes(resourceId)) {
            policy.exclude = policy.exclude.filter((id) => id !== resourceId);
          }

          if (!policy.include?.includes(resourceId)) {
            policy.include = [...policy.include, resourceId];
          }
        }

        await dal.backupPolicy.update(policy);
      }
    } finally {
      props.onClose();
    }
  };

  return (
    <MainDialog
      isOpen={true}
      isLoading={isLoading}
      header={{
        title: 'Assign to backup policy',
        subtitle:
          'Choose the backup policies you want to assign the selected resources to.',
        onClose: props.onClose,
      }}
      footer={{
        primaryButton: {
          icon: 'material-symbols-bookmark-add-outline',
          text: 'Assign',
          disabled: isLoading || updating,
          onClick: assignToPolicy,
          'data-testid': 'assign-to-policy-button',
        },
        secondaryButton: {
          'data-testid': 'cancel-assign-to-policy-button',
          onClick: props.onClose,
          text: 'Cancel',
        },
      }}
    >
      <Box
        className='px-[40px]'
        sx={{
          maxHeight: '300px',
          overflow: 'auto',
        }}
      >
        {body?.backupPolicies.map((policy) => (
          <Stack
            direction='row'
            alignItems='center'
            key={policy.id}
            sx={{ py: '4px' }}
          >
            <Checkbox
              value={policy.id}
              checked={selectedPolicies.some((p) => p.id === policy.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedPolicies([...selectedPolicies, policy]);
                } else {
                  setSelectedPolicies(
                    selectedPolicies.filter((p) => p.id !== policy.id)
                  );
                }
              }}
            />
            <BackupPolicyTag policy={policy} />
          </Stack>
        ))}
      </Box>
    </MainDialog>
  );
}
