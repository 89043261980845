import {
  Box,
  Button,
  capitalize,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { type ControlRules } from '@repo/api-gw-sdk';
import type { Duration } from 'dayjs/plugin/duration';
import { useMemo, useRef, useState } from 'react';

import {
  durationOptions,
  toDays,
  type DurationFragment,
} from '@/utils/dateTime';
import { dayjs } from '@/utils/dayjs';

export function MaximumRetentionEditor({
  rules,
  onChange,
}: {
  rules: ControlRules;
  onChange: (rules: ControlRules) => void;
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const duration = useMemo(
    () =>
      dayjs.duration({
        days: rules.maximumRetention?.maximumRetention || 1,
      }),
    [rules.maximumRetention?.maximumRetention]
  );
  const [selectedFragment, setSelectedFragment] = useState<DurationFragment>(
    () => {
      if (duration.asYears() >= 1 && duration.asYears() % 1 === 0)
        return 'years';
      if (duration.asMonths() >= 1 && duration.asMonths() % 1 === 0)
        return 'months';
      if (duration.asWeeks() >= 1 && duration.asWeeks() % 1 === 0)
        return 'weeks';
      return 'days';
    }
  );

  const update = (value: number) => {
    onChange({
      maximumRetention: {
        enabled: true,
        maximumRetention: toDays(selectedFragment, value),
      },
    });
  };

  return (
    <>
      <Button ref={anchorRef} onClick={() => setOpen(true)}>
        {duration.humanize()}{' '}
        <i className='material-symbols-arrow-drop-down-rounded text-xl' />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
      >
        <Box className='flex flex-col p-[24px] gap-[24px] w-[300px]'>
          <Typography variant='subtitle1'>
            Choose a retention time counter
          </Typography>
          <FragmentSelector
            fragment={selectedFragment}
            onChange={setSelectedFragment}
          />
          <DurationSelector
            fragment={selectedFragment}
            duration={duration}
            onChange={update}
          />
        </Box>
      </Popover>
    </>
  );
}

export const FragmentSelector = (props: {
  fragment: DurationFragment;
  onChange: (value: DurationFragment) => void;
}) => {
  const { fragment, onChange } = props;

  return (
    <Select
      data-testid='fragment-selector'
      className='w-[200px]'
      value={fragment}
      onChange={(e) => onChange(e.target.value as DurationFragment)}
    >
      {durationOptions
        // .filter((option) => enableHourlyBackup || option !== 'hours')
        .filter((option) => option !== 'hours')
        .map((option) => (
          <MenuItem key={option} value={option}>
            {capitalize(option)}
          </MenuItem>
        ))}
    </Select>
  );
};

export const DurationSelector = (props: {
  fragment: DurationFragment;
  duration: Duration;
  onChange: (durationByFragment: number) => void;
}) => {
  const { fragment, duration, onChange } = props;

  const durationValue = useMemo(
    () =>
      Math.round(duration[`as${capitalize(fragment)}` as DurationFragment]()),
    [duration, fragment]
  );

  return (
    <Box className='flex items-center justify-between'>
      How many {fragment}?{' '}
      <TextField
        type='number'
        inputProps={{
          min: 1,
          step: 1,
          pattern: '[0-9]*',
          inputMode: 'numeric',
        }}
        sx={{
          width: 80,
          '& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0,
            },
        }}
        value={durationValue}
        onChange={(e) => onChange(+e.target.value)}
      />
    </Box>
  );
};
