import {
  apps,
  accountId,
  backupPolicies,
  backupRegions,
  backupStorageSize,
  dataClasses,
  environment,
  resourceId,
  resourceName,
  backupStatus,
  resourceType,
  sourceRegion,
  sourceStorageSize,
  subnets,
  clusterName,
  tags,
  vpc,
  vaults,
  id,
  genericSnapshotsDistinctDateCount,
  dateDiscovered,
  createdTime,
  resourceGroupName,
  cloudProvider,
  securityScanConclusion,
} from '@/components/queryBuilder/properties';

export const inventoryListTableColumns = [
  id,
  backupStatus,
  resourceName,
  cloudProvider,
  resourceType,
  resourceId,
  resourceGroupName,
  tags,
  securityScanConclusion,
  dataClasses,
  environment,
  apps,

  backupPolicies,
  vaults,
  backupRegions,
  sourceStorageSize,
  backupStorageSize,
  genericSnapshotsDistinctDateCount,
  dateDiscovered,

  accountId,
  sourceRegion,
  vpc,
  subnets,
  clusterName,
  createdTime,
];
